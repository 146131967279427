import axios from "axios";
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;

const API_URL = DOMAIN_NAME + "/api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      }, {
        withCredentials: true
      })
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem("user");
    return axios
      .post(API_URL + "signOut", {}, {
        withCredentials: true
      })
      .then(response => {
        return response.data;
      });
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  editRole(username, roles) {
    return axios
      .post(API_URL + "editPermission", {
        username,
        roles
      }, {
        withCredentials: true
      })
      .then(response => {
        return response.data;
      });
  }

  deleteUser(username) {
    return axios
      .post(API_URL + "deleteUser", {
        username
      }, {
        withCredentials: true
      })
      .then(response => {
        return response.data;
      });
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('user'));;
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;