import React, { Component } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

export default class UnauthorizedUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getUnauthorizedBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <div>
        <div class="unauthorized-msg">
          <div class="error-code">
            <p id="number1">4</p><p id="number2">0</p><p id="number3">1</p>
          </div>     
        </div>
        <div class="title-error">
          <h1> Unauthorized: Access is denied due to invalid credentials </h1>     
        </div>
        <div class="text-return">
          <p> You do not have permission to view this directory or page using the credentials that you supplied. </p>     
        </div>
      </div>
    );
  }
}
