import axios from 'axios';
import authHeader from './auth-header';
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;

const API_URL = DOMAIN_NAME + "/api/test/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getAdminOrModeratorBoard() {
    return axios.get(API_URL + 'admin-mod', { headers: authHeader() });
  }

  getUnauthorizedBoard() {
    return axios.get(API_URL + 'unauthorized-user', { headers: authHeader() });
  }
}

const userServiceInstance = new UserService();
export default userServiceInstance;