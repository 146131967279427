import React, { Component } from "react";
import { IconContext } from "react-icons";
import { RiDeleteBin5Fill } from "react-icons/ri";

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      selectedRoles: {},
      successMessage: "",
      errorMessage: ""
    };
  }

  componentDidMount() {
    UserService.getAdminOrModeratorBoard().then(
      (response) => {
        this.setState({
          content: response.data.users
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  handleRoleChange = (event, username) => {
    const newSelectedRoles = { ...this.state.selectedRoles, [username]: event.target.value };
    this.setState({ selectedRoles: newSelectedRoles });
  };

  saveRoleChange = () => {
    const { selectedRoles, content } = this.state;
    
    // Count existing moderators
    const currentModerators = content.filter(user => user.role === 'moderator').length;
    
    // Count potential moderators after changes
    const changesToModerator = Object.values(selectedRoles).filter(role => role === 'moderator').length;
    const changesFromModerator = Object.entries(selectedRoles).filter(([username, role]) => {
      const user = content.find(u => u.username === username);
      return user && user.role === 'moderator' && role !== 'moderator';
    }).length;

    const totalModerators = currentModerators + changesToModerator - changesFromModerator;

    // If there are no moderators, allow assigning the first one
    if (currentModerators > 0 && totalModerators < 1) {
      this.setState({
        errorMessage: "There must be at least one moderator.",
        successMessage: ""
      });
      return;
    }

    const promises = Object.entries(selectedRoles).map(([username, role]) =>
      AuthService.editRole(username, role)
    );

    Promise.all(promises)
      .then(() => {
        this.setState(
          {
            successMessage: "Roles updated successfully!",
            errorMessage: "",
            selectedRoles: {}
          },
          () => {
            window.location.reload();
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "An error occurred while updating roles. Please try again.",
          successMessage: ""
        });
      });
  };

  deleteUser = (username) => {
    // Check if the user is the last moderator
    const { content } = this.state;
    const moderators = content.filter(user => user.role === 'moderator');

    if (moderators.length === 1 && moderators[0].username === username) {
      this.setState({
        errorMessage: "Cannot delete the last moderator.",
        successMessage: ""
      });
      return;
    }

    const confirmDelete = window.confirm(`Are you sure you want to delete user ${username}?`);
    if (!confirmDelete) {
      return; // Exit if the user cancels the confirmation
    }

    AuthService.deleteUser(username)
      .then(() => {
        this.setState({
          successMessage: `User ${username} deleted successfully!`,
          errorMessage: ""
        });

        // Update the state to remove the deleted user from the list
        this.setState((prevState) => ({
          content: prevState.content.filter(user => user.username !== username)
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: `Failed to delete user ${username}. Please try again.`,
          successMessage: ""
        });
      });
  };

  render() {
    const { content, successMessage, errorMessage, selectedRoles, currentModerators } = this.state;
    const hasRoleChanges = Object.keys(selectedRoles).length > 0;

    return (
      <div>
        <header className="header">
          <h1 className="page-title">Account & Members</h1>
          <div className="page-description">Manage team members and roles</div>
        </header>

        <div className="permission-section">
          <div className="content-box">
            {Array.isArray(content) ? (
              content.map((user, index) => {
                const isLastModerator = content.filter(u => u.role === 'moderator').length === 1 && user.role === 'moderator';

                return (
                  <div className="profile" key={index}>
                    <div className="profile-info">
                      <div>
                        <div className="username">@{user.username}</div>
                        {user.role ? (
                          <div className="role">{user.role}</div>
                        ) : (
                          <div className="new-user">New User</div>
                        )}
                      </div>
                    </div>

                    {user.role === "admin" ? (
                      <p className="admin-role d-role">Admin</p>
                    ) : (
                      <div className="selection">
                        <div className="role-select">
                          <select
                            name="roles"
                            className="d-role"
                            id="role-changed"
                            onChange={(e) => this.handleRoleChange(e, user.username)}
                            defaultValue={user.role}
                            disabled={isLastModerator && currentModerators > 1} // Disable if last moderator and more than 1 moderator exists
                          >
                            <option value="">---</option>
                            <option value="user">User</option>
                            <option value="moderator">Moderator</option>
                          </select>
                        </div>
                        <button
                          className="delete-user"
                          onClick={() => this.deleteUser(user.username)}
                          disabled={isLastModerator && currentModerators > 1} // Disable if last moderator and more than 1 moderator exists
                        >
                          <IconContext.Provider value={{ className: "delete-user-icon" }}>
                            <RiDeleteBin5Fill />
                          </IconContext.Provider>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>{content}</div>
            )}

            {errorMessage && (
              <div className="error-prompt">
                <div className="error-img">
                  <svg aria-hidden="true" className="error-icon" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                </div>
                <div>
                  <span className="error-message">{errorMessage}</span>
                </div>
              </div>
            )}

            {successMessage && <span className="success-message">{successMessage}</span>}
            
            {hasRoleChanges && (
              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.saveRoleChange}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
