import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { IconContext } from "react-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Home from "./components/home.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import BoardUser from "./components/board-user.component";
import UnauthorizedUser from "./components/unauthorized-user.component";
import Settings from "./components/settings.component";

import EventBus from "./common/EventBus";

import { ReactComponent as Logo } from "./logo.svg";
import { IoSettings } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { CgProfile } from "react-icons/cg";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showAdminOrModeratorBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        showAdminOrModeratorBoard: user.roles.includes("ROLE_MODERATOR") || user.roles.includes("ROLE_ADMIN"),
        currentUser: user,
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showAdminOrModeratorBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showAdminOrModeratorBoard } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            <Logo className="navbar-logo" />
          </Link>
          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item profile-menu">
                <span className="profile-user">{currentUser.username}</span>
                <IconContext.Provider value={{ className: "navbar-profile" }}>
                  <CgProfile />
                </IconContext.Provider>
              </li>

              {showAdminOrModeratorBoard && (
                <li className="nav-item">
                  <Link to={"/settings"} className="nav-link nav-loggedIn">
                    <div className="btn-logged-in">
                      <IconContext.Provider value={{ className: "navbar-loggedIn" }}>
                        <IoSettings />
                      </IconContext.Provider>
                    </div>
                  </Link>
                </li>
              )}

              <li className="nav-item">
                <a href="/login" className="nav-link nav-loggedIn" onClick={this.logOut}>
                  <div className="btn-logged-in">
                    <IconContext.Provider value={{ className: "navbar-loggedIn" }}>
                      <IoMdLogOut />
                    </IconContext.Provider>
                  </div>
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <div className="btn-login">
                  <Link to={"/login"} className="nav-link">
                    Login
                  </Link>
                </div>
              </li>

              <li className="nav-item btn-register">
                <div className="btn-register">
                  <Link to={"/register"} className="nav-link">
                    Sign Up
                  </Link>
                </div>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/unauthorized-user" element={<UnauthorizedUser />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>

      </div>
    );
  }
}

export default App;
